import css from './PageLoader.module.scss'

const PageLoader = () => (
  <div className={`${css.LoaderBkg} landing`}>
    <div className={css.Loader}></div>
    <img
      src={window.runConfig.logo}
      alt={window.runConfig.title}
      className={css.Logo}
    />
  </div>
)

export default PageLoader
