import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './styles/app.scss'

import PageLoader from './components/PageLoader/PageLoader'

const AppLazy = lazy(() => import('./App'))

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Suspense fallback={<PageLoader />}>
      <AppLazy />
    </Suspense>
  </React.StrictMode>
)
